<template>
<v-container   style="margin-top:160px;" >
    <v-container v-if="!linked||1">
 <v-card v-if="this.allowed['atb']==1" elevation="10" @click.native="$router.push({ name: 'Withdraw_atb' })" class="rounded-card" style="margin-bottom: 17px;">
       
           
       <v-img src="img/new_emo_atb.png" max-width="100%" contain center></v-img>
     
 </v-card>
 
 <v-card elevation="10" @click.native="$router.push({ name: 'Withdraw_epi' })" class="rounded-card" style="margin-bottom: 17px;">
       
           
       <v-img src="img/new_emo_epi.png" max-width="100%" contain center></v-img>
      
 </v-card>
 <v-card elevation="10" @click.native="$router.push({ name: 'Withdraw_novus' })" class="rounded-card" style="margin-bottom: 17px;">
       
           
       <v-img src="img/new_emo_novus.png" max-width="100%" contain center></v-img>
      
 </v-card>
 <v-card elevation="10" @click.native="$router.push({ name: 'Withdraw_fishka' })" class="rounded-card" style="margin-bottom: 17px;">
       
           
       <v-img src="img/new_emo1.png" max-width="100%" contain center></v-img>
      
 </v-card>
      <v-card elevation="10" @click.native="$router.push({ name: 'Withdraw_silpo' })" class="rounded-card" style="margin-bottom: 17px;">
      
            <v-img src="img/new_emo2.png" max-width="100%" contain center></v-img>
           
      </v-card>
      <v-card elevation="10" @click.native="$router.push({ name: 'Withdraw_mob' })" class="rounded-card" style="margin-bottom: 17px;">
    
           
            <v-img src="img/new_emo3.png" max-width="100%" contain center></v-img>
           
      </v-card>
      <v-card elevation="10" @click.native="$router.push({ name: 'Withdraw_eva' })" class="rounded-card" style="margin-bottom: 17px;">
        
           
            <v-img src="img/new_emo4.png" max-width="100%" contain center></v-img>
           
      </v-card>
      <v-card elevation="10" @click.native="$router.push({ name: 'Withdraw_rozetka' })" class="rounded-card" style="margin-bottom: 17px;">
          
           
            <v-img src="img/new_emo5.png" max-width="100%" contain center></v-img>
           
      </v-card>

      <v-card elevation="10" @click.native="$router.push({ name: 'Withdraw_wog' })" class="rounded-card" style="margin-bottom: 17px;">
          
           
            <v-img src="img/new_emo6.png" max-width="100%" contain center></v-img>
           
      </v-card>




       
          <v-overlay :value="isLoading">
      <v-progress-circular indeterminate
        color="red">
        
      </v-progress-circular>
      <br />
          </v-overlay>
    </v-container>
</v-container>
</template>

<script>
import btnrnd from "../components/btnrnd.vue";
// eslint-disable-next-line no-unused-vars
import axios from "axios";
// eslint-disable-next-line no-unused-vars
import { API_LOCATION } from "../../config";
export default {
  name: "Info",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    btnrnd,
  },
  data: () => ({
    sms_e:false,
      date: new Date().toISOString().substr(0, 10),
    linked:true,
    
    amount:null,
    personal_number: null,
    oppa_account:null,
    transaction_id:"",
    sms_verify_code:"",
    modal: false,
    menu2: false,
    isLoading:false,
    allowed:[],
    month_lang:{
        'ge':['იანვარი','თებერვალი','მარტი','აპრილი','მაისი','ივნისი','ივლისი','აგვისტო','სექტემბერი','ოქტომბერი','ნოემბერი','დეკემბერი'],
        'ru':['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь']
      },
    
  }),
  computed: {
    task1: function () {
      return this.cards.filter((i) => i.type == "1");
    },
    task2: function () {
      return this.cards.filter((i) => i.type == "2");
    },
    task3: function () {
      return this.cards.filter((i) => i.type == "3");
    },
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    authStatus: function () {
      return this.$store.getters.authStatus;
    },
    page_title: function () {
      return this.$store.getters.page_title;
    },
    fname: function () {
      return this.$store.getters.fname;
    },
    lname: function () {
      return this.$store.getters.lname;
    },
    points: function () {
      return this.$store.getters.points;
    },
    avatar: function () {
      return this.$store.getters.avatar;
    }, 
    title() {
        return this.$t('title')
      },

    date_locale(){
        if(this.$i18n.locale=='ru')
        {
            return 'ru-RU';
        }
        else
        {
            return 'ka-GE';
        }
    }
  },
  watch:{
      title: function(){
        this.$store.dispatch("page_title_set", this.title);
      }
  },
  mounted() {},
  created() {
    this.$store.dispatch("page_title_set", this.title);
    this.$store.dispatch("profileset");
    this.get_allowed_cert();
  },
  methods:{
    get_allowed_cert: function () {
     
     this.isLoading=true;
     axios
     .post(
       API_LOCATION,
       {
         
         action: "get_allowed_cert" 
         
       },
       { headers: { "content-type": "application/x-www-form-urlencoded" } }
     )
     .then((resp) => {
     
       console.log('ALLOWED:'+resp.data); 
       this.allowed=resp.data;
        this.isLoading=false;
       
     });
     
   },
  }
};
</script>

<i18n>
{
  
  "ua":{
      "title": "Обмін балів"
      


      

  }
}
</i18n>

<style scoped>
.rounded-card{
    border-radius:25px;
}
</style>